import React from "react";
import { Joystick } from 'react-joystick-component';
import { useContext } from "react"
import { SocketContext } from "./ConnectionContext"

function JoystickImpl() {
    const { dataConnection, send_controls } = useContext(SocketContext)
    const onMove = (move) => {
        let controls_command =
        {
            controls: {
                "x": move.x,
                "y": move.y
            }
        }
        let str = JSON.stringify(controls_command);
        send_controls(str);
    }
    const onStop = (move) => {
        let controls_command =
        {
            controls: {
                "x": 0.0,
                "y": 0.0
            }
        }
        let str = JSON.stringify(controls_command);
        send_controls(str);
    }
    return (
        < div >
            {/* user's video */}
            {dataConnection && (

                <div className="joystick-wrapper">
                    <       Joystick size={100} throttle={300}
                        move={onMove} stop={onStop} sticky={false} baseColor="red" stickColor="blue"></Joystick>
                </div>)
            }
        </div >)
}

export default JoystickImpl;