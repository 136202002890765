import VideoPlayer from './VideoPlayer';
import Joystick from "./Joystick";
import React from 'react';
import { SocketContext } from "./ConnectionContext"
import { useContext } from "react"
import { Button, Row, Container } from 'react-bootstrap';

export default function App() {
  const { connect, receivedVideo } = useContext(SocketContext)
  return (<div>
    {/* user's video */}
    {!receivedVideo && (
      <Container>
        <Row>
          <Button variant="primary" onClick={connect} size="lg">
            VERBINDUNG ZUM PLUESCHBOT HERSTELLEN
          </Button>
        </Row>
      </Container>)
    }
    <VideoPlayer></VideoPlayer>
    <Joystick />
  </div>)
}