import { SocketContext } from "./ConnectionContext"
import { useContext } from "react"
const VideoPlayer = () => {

  const {receivedVideo, userVideo } = useContext(SocketContext)
  return (
    receivedVideo && <div className="video-container">
      <video playsInline ref={userVideo} width="600" autoPlay />
    </div>
  )
}
export default VideoPlayer