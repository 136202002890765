import { createContext, useRef, useState } from 'react';


const SocketContext = createContext();




const ContextProvider = ({ children }) => {
    const [receivedVideo, setReceivedVideo] = useState(false);
    const [dataConnection, setdataConnection] = useState(false);
    const userVideo = useRef();
    const connectionRef = useRef();
    const socketRef = useRef();
    const dataRef = useRef();
    const connect = () => {
        const socket = new WebSocket('wss://flauschbot.de:8000');
        socketRef.current = socket;
        console.log("Clicked Connecting");
        let peerConnection = new RTCPeerConnection({
            iceServers: [
                {
                    urls: "turn:142.132.169.180:3478",
                    username: "bert",
                    credential: "bert123",
                },
            ],
        });
        connectionRef.current = peerConnection;
        socket.onopen = start;
        socket.onmessage = gotMessageFromserver;
    };


    const send_controls = (data) => {
        console.log("send message on data channel")
        dataRef.current.send(data);
    };

    function start() {
        console.log('start');
        setReceivedVideo(true);
        socketRef.current.send("client connected");
        connectionRef.current.ontrack = gotRemoteStream;
        connectionRef.current.onicecandidateerror = errorHandler
        connectionRef.current.onconnectionstatechange = connectionStateChange
        connectionRef.current.onicegatheringstatechange = gatherState
        connectionRef.current.addTransceiver('video', { direction: 'recvonly' });
        let dataChannel = connectionRef.current.createDataChannel("dataChannel");
        dataRef.current = dataChannel;
        dataChannel.onopen = onOpenDataChannel;
        connectionRef.current.ondatachannel = onDataChannel; 
        connectionRef.current.createOffer().then(descriptionReady);
    };

    function descriptionReady(description) {
        connectionRef.current.setLocalDescription(description);
    }


    function gotMessageFromserver(message) {
        console.log('got servermessage');
        if(message.data == null)
        {
            console.log('empty server message');
            return;
        }
        console.log(message.data);
        const signal = JSON.parse(message.data);
        if (signal.sdp) {
            connectionRef.current.setRemoteDescription(new RTCSessionDescription(signal)).then(() => {
            }).catch(errorHandler);
        }
    }

    function errorHandler(error) {
        console.log(error);
    }

    function gotRemoteStream(event) {
        console.log('got remote stream');
        var srcStream = new MediaStream();
        srcStream.addTrack(connectionRef.current.getReceivers()[0].track);
        userVideo.current.srcObject = srcStream;
    }

    function connectionStateChange(error) {
        console.log("connection changed to ", connectionRef.current.connectionState);
    }

    function gatherState(event) {
        console.log("pc gatherstate is", connectionRef.current.iceGatheringState);
        if (connectionRef.current.iceGatheringState === "complete") {
            console.log("description sent: ", connectionRef.current.localDescription)
            socketRef.current.send(JSON.stringify({ 'sdp': connectionRef.current.localDescription }));
        }
    }

    function onOpenDataChannel(event){
        console.log("opened data channel")
        console.log(event)
        setdataConnection(true);
    }

    function onDataChannel(event){
        console.log("on data channel");
        console.log(event);
    }
    return (
        <SocketContext.Provider value={{
            userVideo,
            send_controls,
            connect,
            receivedVideo,
            dataConnection
        }}
        >
            {children}
        </SocketContext.Provider>
    );
};
export { ContextProvider, SocketContext };